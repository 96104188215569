import React from "react";
import { useTranslation } from 'react-i18next';

import "./Home.css";

function Home() {
  const { t } = useTranslation();

  return (
    <div className="home-container">
      <div className="home-title">
        <h1>{t('homeTitle')}</h1>
      </div>
      <div className="home-about">
        <div className="home-about-info">
          <h1>{t('aboutMe')}</h1>
          <p>{t('firstParagraph')}</p>
          <p>{t('secondParagraph')}</p>
          <p>{t('thirdParagraph')}</p>
        </div>
        <div className="home-about-image">
          <img
            src="./GPTed-developer.png"
            alt="Descripción de la imagen"
            title="Made by Dall-e"
          />
        </div>
      </div>
    </div>
  );
}

export default Home;
