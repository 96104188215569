import React, { useState } from "react";
import { Menu, Icon, Dropdown } from "semantic-ui-react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import "./NavBar.css";

function NavBar() {
  const { t } = useTranslation();
  const [activeItem, setActiveItem] = useState("");
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const handleItemClick = (name) => {
    setActiveItem(name);
    // Opcionalmente, cerrar el menú en móviles después de un click
    setIsMobileMenuVisible(false);
  };

  const mobileMenuItems = [
    { key: "home", name: "home", content: t("home"), to: "home" },
    { key: "work", name: "work", content: t("work"), to: "work" },
    { key: "skills", name: "skills", content: t("skills"), to: "skills" },
    { key: "contact", name: "contact", content: t("contact"), to: "contact" },
  ];

  return (
    <Menu borderless className="neubrutalism-nav">
      <Menu.Item
        name="home"
        as={Link}
        to="home"
        spy={true}
        offset={-70}
        smooth={true}
        duration={500}
        active={activeItem === "home"}
        onClick={() => handleItemClick("home")}
      >
        {t("home")}
      </Menu.Item>
      <Menu.Item
      className="hide-on-mobile"
        name="work"
        as={Link}
        to="work"
        spy={true}
        offset={-70}
        smooth={true}
        duration={500}
        active={activeItem === "work"}
        onClick={() => handleItemClick("work")}
      >
        {t("work")}
      </Menu.Item>
      <Menu.Item
      className="hide-on-mobile"
        name="skills"
        as={Link}
        to="skills"
        spy={true}
        offset={-70}
        smooth={true}
        duration={500}
        active={activeItem === "skills"}
        onClick={() => handleItemClick("skills")}
      >
        {t("skills")}
      </Menu.Item>
      <Menu.Item
        name="contact"
        as={Link}
        to="contact"
        spy={true}
        offset={-70}
        smooth={true}
        duration={500}
        active={activeItem === "contact"}
        onClick={() => handleItemClick("contact")}
      >
        {t("contact")}
      </Menu.Item>
      <Menu.Item
        position="right"
        onClick={() => setIsMobileMenuVisible(!isMobileMenuVisible)}
        className="mobile-menu-icon"
      >
        <Icon name="bars" />
      </Menu.Item>
      {/* Menú desplegable para móviles */}
      {isMobileMenuVisible && (
        <Dropdown.Menu className="mobile-dropdown-menu">
          {mobileMenuItems.map((item) => (
            <Dropdown.Item
              className="mobile-dropdown-item"
              key={item.key}
              as={Link}
              to={item.to}
              spy={true}
              smooth={true}
              duration={500}
              offset={-70}
              onClick={() => handleItemClick(item.name)}
            >
              {item.content}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
    </Menu>
  );
}

export default NavBar;
