import React, { useState, useRef, useEffect } from "react";
import "./MySkills.css";
import { useTranslation } from "react-i18next";

const Terminal = ({ currentCommand }) => {
  const { t } = useTranslation();

  const [input, setInput] = useState(t("inputTerminal"));
  const [output, setOutput] = useState([]);
  const [showProTip, setShowProTip] = useState(true);

  const inputRef = useRef(null);
  const divRef = useRef(null);
  const outputRef = useRef(null);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      runCommand(input);
      setInput("");
    }
  };

  const runCommand = (command) => {
    let result;
    let updatedOutput = [...output];

    switch (command.toLowerCase()) {
      case "-h":
      case "help":
        result = t("helpMessage");
        break;
      case "apps":
        result = JSON.stringify(
          {
            "Apps I Use": [
              "Notion: 2 years",
              "VSCode: 4 years",
              "Teams: 1 year",
              "Figma: 2 years",
            ],
          },
          null,
          2
        );
        break;
      case "programming":
        result = JSON.stringify(
          {
            "Programming Languages": [
              "JavaScript: 4 years",
              "TypeScript: 2 years",
              "Java: 1 years",
              "Python: 1 years",
            ],
          },
          null,
          2
        );
        break;
      case "frameworks":
        result = JSON.stringify(
          {
            Frameworks: {
              FrontEnd: ["React: 3 years", "NextJs: 2 years", "VueJs: 1 year"],
              BackEnd: ["Koa: 1 year", "Sequelize: 1 year"],
              "Design(UI)": ["AntDesign: 1 year", "MUI: 2 years"],
            },
            Libraries: ["Redux: 1 year", "Pinia: 1 year"],
          },
          null,
          2
        );
        break;
      case "version":
        result = JSON.stringify(
          {
            VersionControl: [
              "Git: 3 years",
              "GitHub: 3 years",
              "BitBucket: 1 year",
            ],
            Deployment: [
              "Docker: 1 year",
              "SSH: 1 year",
              "BitBucket Pipelines: 1 year",
            ],
          },
          null,
          2
        );
        break;
      case "management":
        result = JSON.stringify(
          {
            Kanban: "1 years",
            Scrum: "1 years",
            TeamsPlanner: "1 years",
          },
          null,
          2
        );
        break;
      case "clear":
        result = "";
        updatedOutput = [];
        break;
      default:
        result = t("unknownCommandMessage");
    }

    updatedOutput.push({
      command,
      result,
    });

    setOutput(updatedOutput);
    setInput("");
  };

  const handleInputFocus = () => {
    if(input === t("inputTerminal")){
      setInput('')
    }
    // New handler for input focus
    setShowProTip(false);
  };

  useEffect(() => {
    if (outputRef.current) {
      const container = outputRef.current.parentElement;
      container.scrollTop = container.scrollHeight;
    }
  }, [output]);

  useEffect(() => {
    let i = 0;
    let command = "";
    let typing;
    if (currentCommand.commandState) {
      typing = setInterval(() => {
        if (i < currentCommand.commandState.length) {
          command += currentCommand.commandState[i];
          setInput(command);
          i++;
        } else {
          clearInterval(typing);
          runCommand(command);
        }
      }, 100);
    }
    return () => {
      clearInterval(typing);
    };
  }, [currentCommand]);

  return (
    <div className="terminal" style={{ position: "relative" }}>
      {showProTip && !currentCommand.commandState && (
        <div
          className="tooltip-box"
          style={{ position: "absolute", bottom: "0", right: "0" }}
        >
          <img
            src={process.env.PUBLIC_URL + "/terminal-arrow-1.png"}
            alt="pro tip arrow"
            className="tooltip-arrow"
          />
          <div>
            <img
              src={process.env.PUBLIC_URL + "/foco-blanco-2.png"}
              alt="pro tip icon"
              className="tooltip-icon"
              style={{
                height: "100px",
              }}
            />
            <section className="tooltip-text">{t("proTip")}</section>
          </div>
        </div>
      )}
      <div className="terminal-header">
        <div className="circle red"></div>
        <div className="circle yellow"></div>
        <div className="circle green"></div>
        <div className="terminal-header-title">~/skills</div>
      </div>
      <div className="terminal-content">
        <div ref={outputRef} style={{ maxHeight: "" }}>
          {output.map((line, index) => (
            <div key={index}>
              <div>{line.command}</div>
              <pre
                className={
                  line.command ? line.command : "default-prompt-result"
                }
              >
                {typeof line.result === "object" ? (
                  <code>{JSON.stringify(line.result, null, 2)}</code>
                ) : (
                  line.result
                )}
              </pre>
            </div>
          ))}
        </div>

        <div ref={outputRef} />
        <span className="prefix">~/skills/</span>
        <input
          ref={inputRef}
          className="terminal-input"
          type="text"
          value={input}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          onFocus={handleInputFocus}
        />
        <div ref={divRef}></div>
      </div>
    </div>
  );
};

export default Terminal;
