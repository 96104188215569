import React, { useState } from "react";
import { Button, Icon, Segment } from "semantic-ui-react";
import "./Contact.css";
import { useTranslation } from "react-i18next";

function Contact() {
  const [isHoveredLinkedIn, setIsHoveredLinkedIn] = useState(false);
  const [isHoveredGitHub, setIsHoveredGitHub] = useState(false);
  const [isHoveredMail, setIsHoveredMail] = useState(false);
  const { t } = useTranslation();

  const downloadCV = () => {
    window.open(
      process.env.PUBLIC_URL + "/Resume Diego Isaac Herrera Ledezma 2023.pdf"
    );
  };

  return (
    <div>
      <Segment className="contact-segment-download">
        <Button primary onClick={downloadCV} className="contact-button">
          <Icon name="download" />
          {t("downloadResume")}
        </Button>
      </Segment>
      <Segment className="contact-segment-social-media">
        <h2 className="contact-segment-h2">{t('establishContact')}</h2>
        <Button.Group className="contact-segment-button-group">
          <Button
            color="linkedin"
            as="a"
            href="https://www.linkedin.com/in/dhlisaac/"
            target="_blank"
            rel="noopener noreferrer"
            className={
              isHoveredLinkedIn
                ? "contact-button contact-button-jiggle"
                : "contact-button"
            }
            onMouseEnter={() => setIsHoveredLinkedIn(true)}
            onMouseLeave={() => setIsHoveredLinkedIn(false)}
          >
            <Icon className="contact-segment-icon" name="linkedin" size="big" />{" "}
            LinkedIn
          </Button>
          <Button
            color="purple"
            as="a"
            href="https://github.com/IzDiego"
            target="_blank"
            rel="noopener noreferrer"
            className={
              isHoveredGitHub
                ? "contact-button contact-button-jiggle"
                : "contact-button"
            }
            onMouseEnter={() => setIsHoveredGitHub(true)}
            onMouseLeave={() => setIsHoveredGitHub(false)}
          >
            <Icon className="contact-segment-icon" name="github" size="big" />{" "}
            GitHub
          </Button>
          <Button
            color="pink"
            as="a"
            href="mailto:dhlisaac@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className={
              isHoveredMail
                ? "contact-button contact-button-jiggle"
                : "contact-button"
            }
            onMouseEnter={() => setIsHoveredMail(true)}
            onMouseLeave={() => setIsHoveredMail(false)}
          >
            <Icon className="contact-segment-icon" name="mail" size="big" />{" "}
            <br></br>
            Mail
          </Button>
        </Button.Group>
      </Segment>
    </div>
  );
}

export default Contact;
