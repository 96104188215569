// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        //home
        homeTitle: "DIEGO HERRERA",
        aboutMe: "About me...",
        firstParagraph:
          "I made and published my first webpage at the age of 12 on my ISP's free hosting platform. It was a guild page for a web-based game.",
        secondParagraph:
          "I am a passionate web developer who creates modern, intuitive, and visually appealing websites. I specialize in building robust web applications with a focus on asynchronous functionalities.",
        thirdParagraph:
          "I have had the opportunity to collaborate with multi-skilled teams, where I not only contribute my expertise but also continuously learn and share knowledge to achieve project goals.",
        //navbar
        home: "Home",
        work: "Work",
        skills: "Skills",
        contact: "Contact",
        //my skills
        appsButton: "Apps I use",
        programmingButton: "Programming \nLanguages",
        frameworksButton: "Frameworks & \nlibraries",
        versionButton: "Version control & \nDeployment",
        managementButton: "Project Management",
        //terminal
        helpMessage:
          "Comandos disponibles:\n" +
          "  clear         Clear the terminal.\n" +
          "  apps          Shows the apps that I use.\n" +
          "  programming   Shows the languages that I know and use.\n" +
          "  frameworks    Lists the frameworks that I know and use.\n" +
          "  version       Lists the software of version control and deployment.\n" +
          "  management    My knowlege in project management methodologies.",
        unknownCommandMessage:
          "Unknown command. Enter '-h' or 'help' to see available commands.",
        proTip: "PRO TIP: Enter -h or help to see available commands",
        inputTerminal: "← Type here",
        //footer
        footer:
          "© 2023 Diego Isaac Herrera Ledezma. All rights reserved.\n This is my take on Neubrutalism design.",
        //contact
        downloadResume: "Download resume",
        establishContact: "Establish contact . .",
        //workExperience
        workTitle: "Work Experience",
        description: "",
        //projectCards
        projectCard01:
          "Web app to manage warehouse personal, re-print missing barcodes and productions by shift. And his respective reports.",
        projectCard02:
          "Maintaned the frontend and backend of a WMS (Warehouse Management System) for a warehouse and logistic company in-house web app.",
        projectCard03:
          "Web app that allowed users to manage and publish content on social media platforms such as Facebook, Instagram, and Twitter.",
      },
    },
    es: {
      translation: {
        //home
        homeTitle: "DIEGO HERRERA",
        aboutMe: "Acerca de mí...",
        firstParagraph:
          "Hice y publiqué mi primera página web a los 12 años en el hosting gratuito de mi proveedor de servicios de Internet. Era una página de gremio para un juego en línea.",
        secondParagraph:
          "Soy un apasionado desarrollador web que crea sitios web modernos, intuitivos y visualmente atractivos. Me especializo en la creación de aplicaciones web robustas con un enfoque en funcionalidades asíncronas.",
        thirdParagraph:
          "He tenido la oportunidad de colaborar con equipos multidisciplinarios, donde no solo aporto mi experiencia, sino que también aprendo y comparto conocimientos de manera continua para lograr los objetivos del proyecto.",
        //navbar
        home: "Inicio",
        work: "Trabajo",
        skills: "Habilidades",
        contact: "Contacto",
        //my skills
        appsButton: "Aplicaciones que utilizo",
        programmingButton: "Lenguajes de \nprogramación",
        frameworksButton: "Frameworks y \nbibliotecas",
        versionButton: "Control de versiones y \nDespliegue",
        managementButton: "Gestión de proyectos",
        //terminal
        helpMessage:
          "Comandos disponibles:\n" +
          "  clear         Limpia la pantalla.\n" +
          "  apps          Muestra las aplicaciones que uso.\n" +
          "  programming   Muestra los lenguajes de programación que uso.\n" +
          "  frameworks    Enlista los frameworks con lo que trabajo.\n" +
          "  version       Enlista los software de version y deployment.\n" +
          "  management    Mis conocimientos sobre la gestión de proyectos.",
        unknownCommandMessage:
          "Comando desconocido. Ingresa '-h' o 'help' para ver los comandos disponibles.",
        proTip:
          "PRO TIP: Ingresa '-h' o 'help' para ver los comandos disponibles.",
        inputTerminal: "← Escribe aqui",
        //footer
        footer:
          "© 2023 Diego Isaac Herrera Ledezma. Todos los derechos reservados.\n Este es mi diseño para neobrutalismo.",
        //contact
        downloadResume: "Descargar CV",
        establishContact: "Establecer contacto . .",
        //work experience
        workTitle: "Experiencia",
        //projectcards
        projectCard01:
          "Aplicación web para gestionar el personal de almacén, reimprimir códigos de barras faltantes y producciones por turno. Y sus respectivos informes.",
        projectCard02:
          "Mantenimiento del frontend y backend de un sistema de gestión de almacenes (WMS) para una aplicación web interna de una empresa de almacén y logística.",
        projectCard03:
          "Aplicación web que permitía a los usuarios gestionar y publicar contenido en plataformas de redes sociales como Facebook, Instagram y Twitter.",
      },
    },
  },
  lng: "en", // Idioma predeterminado
  fallbackLng: "en", // Idioma de respaldo en caso de que no se encuentre una traducción
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
