import React from 'react';
import { Segment } from 'semantic-ui-react';
import './Footer.css';
import { useTranslation } from 'react-i18next'; // Importa useTranslation

function Footer() {
    const { t } = useTranslation();
    return (
        <Segment inverted vertical className="footer-segment">
            <div className="footer-content">
            {t('footer')}
            </div>
        </Segment>
    );
}

export default Footer;
