import React from "react";
import ProjectCard from "./ProjectCard";
import "./WorkExperience.css";
import { useTranslation } from "react-i18next";

function WorkExperience() {
  const { t } = useTranslation();

  return (
    <div className="work-experience-container">
      <div className="work-experience-title">
        <h2>{t("workTitle")}</h2>
      </div>
      <div className="work-experience-card-container">
        <div className="work-experience-row">
          <ProjectCard
            title={"QuickConsulting"}
            subtitle={
              t('projectCard01')
            }
            tech={["Vite", "Vue", "AntDesign"]}
          ></ProjectCard>
          <ProjectCard
            title={"QuickConsulting"}
            subtitle={
              t('projectCard02')
            }
            tech={["NextJs", "React", "AntDesign"]}
          ></ProjectCard>
        </div>
        <ProjectCard
          title={"LCM Consultoría"}
          subtitle={
            t('projectCard03')
          }
          tech={["NextJs", "React", "Tailwind"]}
        ></ProjectCard>
      </div>
    </div>
  );
}

export default WorkExperience;
